<template>
  <ModalMain
    @close="close"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
<article class="animate-fade-in">
        <div class="text-center block">
          <span class="icon-ico-meth-fill text-[42px] text-blue-500 animate-glow-slow-blue text-center"/>
          <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">What is M.ETH?</h1>
          <div class="text-slate-100 mt-4 mb-4 leading-tight animate-slide-down-fade-in04s text-center">
            <p>M.ETH is a virtual currency you can earn interacting with MetaWin, please head over to <a class="text-cyan hover:text-blue-400" href="https://discord.gg/the-arena" target="_blank">The Arena Discord</a> to find out more.</p>
          </div>
        </div>
      </article>
</div>
  </ModalMain>
</template>

<script>
import { mapWritableState, mapState } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';

export default defineComponent({
  name: 'ModalMethMain',
  data() {
    return {};
  },
  computed: {
    ...mapWritableState(useUiStore, ['showMethModal',]),
    ...mapState(useAuthStore, ['isUserLogged',]),
  },
  watch: {
    isUserLogged(val) {
      if (val) { this.close(); }
    },
  },
  mounted() {
    this.$gtmCustomEvent({ event: 'modal_show', action: 'modalOpen', modalName: 'Meth', });
  },
  methods: {
    close() {
      this.showMethModal = false;
    },
  },
});
</script>
